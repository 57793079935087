import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ad62616"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkSelectId(_ctx.info))),
    class: "d-flex justify-content-center align-items-center",
    style: {"width":"60px","height":"60px"}
  }, [
    _createElementVNode("img", {
      class: _normalizeClass([_ctx.selected ? 'selected' : '', "photo"]),
      src: _ctx.info.avatar,
      alt: ""
    }, null, 10, _hoisted_1)
  ]))
}