
import { defineComponent } from "vue";
import axios from "axios";
import Bus from "@/utils/Bus";
import { Tooltip } from "bootstrap";
import router from "@/router";
import { Modal } from "bootstrap";

import DefaultPhoto from "@/components/modal/DefaultPhoto.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
const devEnv = process.env.VUE_APP_ENV;

interface userInfo {
  photo: string;
  language: string;
  teacherId: number;
  lineBound: boolean;
}

export default defineComponent({
  name: "MemberInfoBasicInfo",
  components: { DefaultPhoto },
  props: ["userInfo"],
  data() {
    return {
      // 以下為「學生資料」
      image: "",
      Motto: "",
      photoLoading: false,
      // 以下為「追蹤老師」
      teacherList: [] as Array<userInfo>,
      modalId: "memberProfile",
      defaultPhotoModal: null as any,
      hideLineBind: false,
    };
  },
  created() {
    devEnv !== "production"
      ? (this.hideLineBind = true)
      : (this.hideLineBind = false);
  },
  //接收更改後的Motto
  mounted() {
    Bus.on("getChangeMotto", (msg: any) => {
      this.changeMotto(msg);
    });

    // 追蹤老師
    // 學生基本資訊
    let a = new Map();
    axios
      .get(`${serverUrl}personal`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.teacherList = res.data.data.user.follow;
          if (this.teacherList.length > 0) {
            var tooltipTriggerList = [].slice.call(
              document.querySelectorAll('[data-bs-toggle="tooltip"]')
            );
            var tooltipList = tooltipTriggerList.map(function (
              tooltipTriggerEl
            ) {
              return new Tooltip(tooltipTriggerEl, {
                container: "body",
                trigger: "hover",
              });
            });
          }
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });

    // Modal 初始化，推薦課程彈出視窗
    this.defaultPhotoModal = new Modal(
      document.getElementById(`${this.modalId}`)! as HTMLElement
    );
  },

  methods: {
    changeMotto(msg: any) {
      this.Motto = msg;
    },
    photo(img: string) {
      return img;
    },
    fileSelected(e: any) {
      var photoFilename = "";
      const file = e.target.files.item(0);
      const reader = new FileReader();
      const uploadImg = document.querySelector(
        "#uploadImg"
      )! as HTMLInputElement;
      reader.addEventListener("load", this.imageLoaded);
      reader.readAsDataURL(file);
      // 呼叫API
      var postforms = new FormData();
      postforms.append("type", "2");
      postforms.append("file", file);
      var config = {
        headers: {
          Authorization: loginToken,
          "Content-Type": "multipart/form-data",
        },
      };
      this.photoLoading = true;
      axios
        .post(`${serverUrl}common/file`, postforms, config)
        .then((res) => {
          if (res.status == 200) {
            photoFilename = res.data.data.fileName;

            axios
              .patch(
                `${serverUrl}personal/photo`,
                {
                  fileName: photoFilename,
                },
                {
                  headers: {
                    Authorization: loginToken,
                  },
                }
              )
              .then((res) => {
                if (res.status == 200) {
                  Bus.emit("getChangePhoto");
                  setTimeout(() => {
                    this.photoLoading = false;
                  }, 500);
                  // console.log("圖片回傳成功");
                }
              });
          }
        })
        .catch((error) => {
          Bus.emit("openAlertModal", {
            type: "error",
            title: ["Error!"],
            content:
              "上傳失敗 ! 請注意圖片容量最大上限是 1MB，或是網路訊號不佳。",
            btnText: "關閉",
          });

          this.photoLoading = false;
          uploadImg.value = "";
          console.log(error);
        });
    },
    imageLoaded(e: any) {
      this.image = e.target.result;
      // console.log("imageLoaded");
      // console.log(this.image);
    },
    teacherlist(img: string) {
      return img;
    },
    hideTooltips(teacherId: any, index: any) {
      var ttEl = (this.$refs.teacherList as any)[index];
      var tooltip = Tooltip.getOrCreateInstance(ttEl as any);
      tooltip.hide();
      router.push(`/teachers/teachersintroduction?id=${teacherId}`);
    },
    showModal() {
      this.defaultPhotoModal.show();
    },
    hideModal() {
      this.defaultPhotoModal.hide();
    },
    changePhoto() {
      this.hideModal();
      Bus.emit("getChangePhoto");
    },
  },
});
