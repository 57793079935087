
import { defineComponent } from "vue";
import axios from "axios";
import DefaultPhotoItem from "@/components/MemberInfo/DefaultPhotoItem.vue";
import Bus from "@/utils/Bus";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "DefaultPhoto",
  components: { DefaultPhotoItem },
  mixins: [],
  props: ["id"],
  emits: ["hideModal", "changePhoto"],
  data() {
    return {
      items: [] as any,
      selectedId: 0,
      photoName: "",
      isPending: false,
      flash: false,
    };
  },
  computed: {},
  watch: {},
  beforeCreate() {
    //
  },
  created() {
    this.defaultPhotoGet();
  },
  beforeMount() {
    //
  },
  mounted() {
    //
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    defaultPhotoGet() {
      axios
        .get(`${serverUrl}avatar`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.items = res.data.data;
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });
    },
    checkSelectId(info: any) {
      this.selectedId = info.id;
      this.photoName = info.file;
    },
    hideModal() {
      this.$emit("hideModal");
    },
    changePhoto(photoName: string) {
      if (photoName) {
        this.isPending = true;
        axios
          .patch(
            `${serverUrl}personal/photo`,
            { fileName: photoName },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.$emit("changePhoto");
              this.isPending = false;
            }
          })
          .catch((error) => {
            console.error(`錯誤： ${error}`);
          });
      } else {
        if (!this.flash) {
          this.flash = true;
          setTimeout(() => {
            this.flash = false;
          }, 1200);
        }
      }
    },
  },
});
