
import { defineComponent } from "vue";

import BasicInfo from "@/components/MemberInfo/BasicInfo.vue";
import Template10 from "@/views/templates/Template10.vue";

export default defineComponent({
  name: "Message",
  components: {
    Template10,
    BasicInfo,
  },
  data() {
    return {};
  },
  methods: {
    changeCategory(name: number) {
      this.$emit("changeCategory", name);
    },
  },
});
