import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInfo = _resolveComponent("BasicInfo")!
  const _component_Template10 = _resolveComponent("Template10")!

  return (_openBlock(), _createBlock(_component_Template10, null, {
    "center-column": _withCtx(() => [
      _createVNode(_component_BasicInfo)
    ]),
    _: 1
  }))
}