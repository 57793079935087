
import { defineComponent } from "vue";
import axios from "axios";
import Bus from "@/utils/Bus";
import Profile from "@/components/MemberInfo/Profile.vue";
import Detail from "@/components/MemberInfo/Detail.vue";
import Follow from "@/components/MemberInfo/Follow.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "MemberInfoBasicInfo",
  components: {
    Profile,
    Detail,
    Follow,
  },
  data() {
    return {
      userInfo: null,
      b_modifydata: [],
      changePhoto: false,
    };
  },

  mounted() {
    this.callAPI();
    Bus.on("cleanData", () => {
      this.userInfo = null;
    });
    Bus.on("changeAPI", () => {
      this.callAPI();
    });
    Bus.on("getChangePhoto", () => {
      this.changePhoto = true;
      this.callAPI();
    });
    // 學生基本資訊;
  },
  methods: {
    callAPI() {
      axios
        .get(`${serverUrl}personal`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.userInfo = res.data.data.user;
            // 避免進來讀取資料 call API 時就直接彈出 Modal
            if (this.changePhoto) {
              Bus.emit("openAlertModal", {
                type: "success",
                title: ["成功提示"],
                content: "您修改的資料已成功儲存",
                btnText: "返回會員資料",
              });
            }
            this.changePhoto = false;
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
          if (this.changePhoto) {
            Bus.emit("openAlertModal", {
              type: "error",
              title: ["儲存失敗"],
              content: "資料儲存似乎發生問題，稍候再試一次",
              btnText: "返回會員資料",
            });
          }
        });
    },
  },
});
