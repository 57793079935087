
import { defineComponent } from "vue";

export default defineComponent({
  name: "DefaultPhotoItem",
  components: {},
  mixins: [],
  props: ["info", "index", "groupName", "selectedId"],
  emits: ["checkSelectId"],
  data() {
    return {};
  },
  computed: {
    selected() {
      if (this.selectedId === this.info.id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    //
  },
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    //
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    checkSelectId(info: any) {
      this.$emit("checkSelectId", info);
    },
  },
});
