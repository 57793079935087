
import { defineComponent } from "vue";
import axios from "axios";
import { Tooltip } from "bootstrap";
import router from "@/router";

interface userInfo {
  photo: string;
  language: string;
}

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  data() {
    return {
      teacherList: [] as Array<userInfo>,
    };
  },
  mounted() {
    let a = new Map();
    axios
      .get(`${serverUrl}personal`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.teacherList = res.data.data.user.follow;

          if (this.teacherList.length > 0) {
            var tooltipTriggerList = [].slice.call(
              document.querySelectorAll('[data-bs-toggle="tooltip"]')
            );
            var tooltipList = tooltipTriggerList.map(function (
              tooltipTriggerEl
            ) {
              return new Tooltip(tooltipTriggerEl, {
                container: "body",
                trigger: "hover",
              });
            });
          }
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
  },
  methods: {
    teacherlist(img: string) {
      return img;
    },
    hideTooltips(teacherId: any, index: any) {
      var ttEl = (this.$refs.teacherList as any)[index];
      var tooltip = Tooltip.getOrCreateInstance(ttEl as any);
      tooltip.hide();
      router.push(`/teachers/teachersintroduction?id=${teacherId}`);
    },
  },
});
