
import { defineComponent, ref } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";
import Bus from "@/utils/Bus";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "MemberInfoBasicInfo",
  props: ["userInfo"],
  mixins: [mixins],

  data() {
    const modifyData = ref({
      Nickname: this.userInfo.nickName as string,
      Motto: this.userInfo.motto as string,
      PhoneNumber: this.userInfo.phone,
      oldpassword: "",
      newpassword: "",
      confirmPassword: "",
    });
    return {
      oldpwdFlag: true,
      pwdFlag: true,
      confirmPwdFlag: true,
      icon_click: true,
      flag: true,
      modifyFlag: false,
      modifyData,
      notification: false,
    };
  },
  methods: {
    input() {
      this.flag = true;
    },
    modify() {
      this.icon_click = false;
      this.modifyFlag = true;
      this.flag = false;
    },
    done() {
      if (
        this.modifyData.oldpassword != "" &&
        (this.modifyData.newpassword === "" ||
          this.modifyData.confirmPassword === "")
      ) {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning!"],
          content: "請輸入新密碼及確認密碼，如不修改密碼請將該欄位保持空白！",
          btnText: "關閉",
        });

        this.flag = false;
        return;
      }
      if (this.modifyData.newpassword != this.modifyData.confirmPassword) {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning!"],
          content: "新密碼輸入不一致!",
          btnText: "關閉",
        });
        this.flag = false; //加上此flag不然會跳出編輯頁面
      } else if (
        this.modifyData.PhoneNumber !== null &&
        (this.modifyData.PhoneNumber.length !== 10 ||
          this.modifyData.PhoneNumber.match(/^09[0-9]/) == null)
      ) {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning!"],
          content: "請輸入有效手機號碼",
          btnText: "關閉",
        });
        this.flag = false; //加上此flag不然會跳出編輯頁面
      } else {
        Bus.emit("cleanData");
        this.modifyFlag = false;
        this.icon_click = true;
        axios
          .patch(
            `${serverUrl}personal`,
            {
              nickName: this.modifyData.Nickname as string,
              currentPassword: this.modifyData.oldpassword as string,
              newPassword: this.modifyData.newpassword as string,
              newPassword_confirmation: this.modifyData
                .confirmPassword as string,
              phone: this.modifyData.PhoneNumber as number,
              motto: this.modifyData.Motto as string,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            // console.log(loginToken);
            if (res.status == 200) {
              this.updateData();
              // console.log("修改成功");
              Bus.emit("openAlertModal", {
                type: "success",
                title: ["Good job!"],
                content: "資料更新成功!",
                btnText: "關閉",
              });
            } else if (res.status == 290) {
              // console.log("422失敗");
              this.updateData();
            }
          })
          .catch((error) => {
            Bus.emit("openAlertModal", {
              type: "error",
              title: ["Error!"],
              content: "資料更新失敗!",
              btnText: "關閉",
            });
            this.updateData();
            // console.log("catch");
            console.log(error);
          });
      }
      this.passwordReset();
    },
    //將更改後資料傳給Profile檔案
    updateData() {
      Bus.emit("getChangeMotto", this.modifyData.Motto);
      Bus.emit("changeAPI");
      // console.log("成功送出emit");
    },
    //密碼不一致時重置
    passwordReset() {
      interface Test {
        newpassword: string;
        img: string;
        lang: string;
      }
      this.modifyData.oldpassword = "";
      this.modifyData.newpassword = "";
      this.modifyData.confirmPassword = "";
    },
    reset() {
      this.modifyData.Nickname = "";
      this.modifyData.Motto = "";
      this.modifyData.PhoneNumber = null;
      this.modifyData.oldpassword = "";
      this.modifyData.newpassword = "";
      this.modifyData.confirmPassword = "";
    },
    changeOldPwd() {
      this.oldpwdFlag = !this.oldpwdFlag;
    },
    changePwd() {
      this.pwdFlag = !this.pwdFlag;
    },
    changeConfirmPwd() {
      this.confirmPwdFlag = !this.confirmPwdFlag;
    },
  },
  // watch: {
  //   notification() {
  //     //  Post to Backend
  //     console.log(this.notification);
  //   },
  // },
});
